import React from 'react';
import Total from "../../Total/Total";
import { setTfsaTotalSpouse } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const TfsaTotalSpouse = ( props ) => {
    const onChange = val => {
        props.setTfsaTotalSpouse(val)
    }

    return (
        < Total
            total={props.tfsaTotal}
            title={'Total in TFSA'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        tfsaTotal: state.savingsAndInvestments.spouse.tfsa.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTfsaTotalSpouse : val => dispatch(setTfsaTotalSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TfsaTotalSpouse);