import React, { useEffect } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import TfsaTotalYou from "../../../../components/ContentRow/Step3/Tfsa/TfsaTotal/TfsaTotalYou";
import TfsaTotalSpouse from "../../../../components/ContentRow/Step3/Tfsa/TfsaTotal/TfsaTotalSpouse";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const TfsaHave = ( props ) => {
    const titles = {
        layoutTitle: 'TFSA',
        sectionTitle: 'How much do you have?',
    };

    const title = "Current TFSA";

    const next = retirementCalcLinks.tfsaContribute;

    useEffect(() => {
        props.thirdStepCompleted(false)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(9/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.you.tfsa.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <TfsaTotalYou/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.spouse.tfsa.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <TfsaTotalSpouse/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TfsaHave);